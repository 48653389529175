@import '../../common/styles/_variable';

.formHolder {
    .form-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        margin-top: 3px;
        .close {
            background: none;
            border: none;
            color: $dark;
        }
        h5 {
            color: $dark;
        }
    }
    .formHolder-row {
        width: 100%;
        .input-group {
            .form-label {
                width: 100%;
                span {
                    margin-right: 6px;
                }
            }
            .inputHolder {
                width: 100%;                
            }
        }
    }
    button {
        float: right;
        svg {
            margin: 0;
        }
    }
}