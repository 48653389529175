@import '../../common/styles/_variable';

body {
  background: transparent;
}

.chatBotHolder {
  // height: 100vh;
}

.iframe-sec {
  position: fixed;
  right: 15px;
  bottom: 0;
}

.chatBotTrigger {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  padding: 15px;
  background: $primary-light;

  img {
    transition: transform 0.3s ease;
  }

  &:hover {
    img {
      transform: rotate(360deg);
    }

  }
}



.chatIframeHolder {
  position: fixed;
  height: var(--window-height);
  width: 100vw;
  bottom: 0;
  right: 0;

  &.chatbot-collapsed {
    height: 60px;

    .chatbot-header {
      .chat-toggle {
        transform: rotate(180deg);
      }
    }
  }

  .chatbot-header {
    background: $primary-color;
    padding: 10px 15px;
    color: $white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    h3 {
      cursor: pointer;
    }

    .chat-logo {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background: $whiteTrans;
    }

    .chat-toggle {
      border: 0;
      min-width: 0;
      width: 35px;
      height: 35px;
      color: $white;
      transition: transform 0.3s ease;

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within,
      &:active {
        outline: 0;
        border: 0;
        color: $white;
      }
    }
  }

  .chatContainer {
    height: 100%;
    width: 100%;
    .json-to-table td {
      min-width: 100% !important;
    }
    .msger {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      max-height: none;
      height: calc(100% - 60px);
    }
  }
}

.float-chat-container {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: flex;
  flex-direction: column;
.arrow-holder {
  display: none;
}
}